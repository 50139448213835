@charset "UTF-8";
@import 'main-variables';

.spacer {
  height: 65px;
  &.spacer-xl {height: 120px;}
  &.spacer-lg {height: 90px;}
  &.spacer-md {height: 50px;}
  &.spacer-sm {height: 30px;}
  &.spacer-xs {height: 15px;}
  @media only screen and (max-width: 767px) {
    height: 30px;
    &.spacer-xl {height: 50px;}
    &.spacer-lg {height: 50px;}
    &.spacer-md {height: 30px;}
    &.spacer-sm {height: 20px;}
    &.spacer-xs {height: 10px;}
  }
}
